@use 'sass:math';
$dWidth: 375;

@function rpx($d) {
  @return math.div($d, $dWidth) * 100 * 1vw;
}

$bg-white: white;
$bg-gray: #f5f5f5;
$border-color: #f5f5f5;
$border-color-gray: #ccc;
$primary-color: #6571ff;
$primary-color-t: rgba(#6571ff, 0.5);
$text-color-green: #18ac18; // #52c41a;
$text-color-origin: #faad14;
$text-color-9: #999999;
$text-color-6: #666666;
$text-color-3: #333333;
$text-color-0: #000000;
$text-color-red: #ff5733;
$text-color-red-t: rgba($text-color-red, 0.1);
$text-color-origin-red: #ff6a0e;
$text-color-origin-red-t: rgba($text-color-origin-red, 0.1);

// 字号
$font-size-12: rpx(12);
$font-size-14: rpx(14);
$font-size-16: rpx(16);
$font-size-18: rpx(18);
$font-size-20: rpx(20);
$font-size-22: rpx(22);
$font-size-24: rpx(24);
$font-size-26: rpx(26);
$fss: 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 40, 48;
// 底部导航栏高度
$bottom-nav-bar-height: rpx(44);

@each $fs in $fss {
  .font-size-#{$fs} {
    font-size: rpx($fs);
  }
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: rpx(14);
  color: $text-color-3;

  input {
    border: none !important;
    width: 1px;
    font-size: rpx(14);
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: transparent;
      color: $text-color-6;
    }
  }
}

.vw-100 {
  width: 100vw;
}

.vh-100 {
  height: 100vh;
}

.bw-100 {
  width: 100%;
}

.bw-50 {
  width: 50%;
}

.bh-100 {
  height: 100%;
}

.bw-90 {
  width: 90%;
}

.bh-90 {
  height: 90%;
}

.bw-60 {
  width: 60%;
}

.bh-60 {
  height: 60%;
}

.bg-black-t7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-gray {
  background-color: $bg-gray;
}

.bg-gray-t2 {
  background-color: rgba($color: $bg-gray, $alpha: 0.2) !important;
}

.bg-white {
  background-color: $bg-white;
}

.bg-p {
  background-color: $primary-color;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-primary-t1 {
  background-color: rgba($color: $primary-color, $alpha: 0.1);
}

.bg-primary-t2 {
  background-color: rgba($color: $primary-color, $alpha: 0.2);
}

.bg-green {
  background-color: $text-color-green;
}

.bg-green-t1 {
  background-color: rgba($color: $text-color-green, $alpha: 0.1);
}

.bg-green-t2 {
  background-color: rgba($color: $text-color-green, $alpha: 0.2);
}

.bg-red {
  background-color: $text-color-red;
}

.bg-red-t1 {
  background-color: rgba($color: $text-color-red, $alpha: 0.1);
}

.bg-red-t2 {
  background-color: rgba($color: $text-color-red, $alpha: 0.2);
}

.bg-red-t3 {
  background-color: rgba($color: $text-color-red, $alpha: 0.3);
}

.bg-red-t4 {
  background-color: rgba($color: $text-color-red, $alpha: 0.4);
}

.bg-red-t5 {
  background-color: rgba($color: $text-color-red, $alpha: 0.5);
}

.bg-origin {
  background-color: $text-color-origin;
}

.bg-origin-t1 {
  background-color: rgba($color: $text-color-origin, $alpha: 0.1);
}

.bg-origin-t2 {
  background-color: rgba($color: $text-color-origin, $alpha: 0.2);
}

.bg-origin-red {
  background-color: $text-color-origin-red;
}

.bg-origin-red-t {
  background-color: $text-color-origin-red-t;
}

.bg-origin-red-t1 {
  background-color: rgba($color: $text-color-origin-red, $alpha: 0.1);
}

.bg-origin-red-t2 {
  background-color: rgba($color: $text-color-origin-red, $alpha: 0.2);
}

.bg-origin-red-t3 {
  background-color: rgba($color: $text-color-origin-red, $alpha: 0.3);
}

.bg-line-origin-red {
  background: linear-gradient(90deg, #fb911a, $text-color-origin-red);
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid $border-color;
}

.border-left {
  border-left: 1px solid $border-color;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border-color-gray {
  border-color: $border-color-gray;
}

.border-color-p {
  border-color: $primary-color;
}

.border-color-p-t1 {
  border-color: rgba($color: $primary-color, $alpha: 0.1);
}

.border-color-p-t2 {
  border-color: rgba($color: $primary-color, $alpha: 0.2);
}

.border-color-9 {
  border-color: $text-color-9;
}

.border-color-3 {
  border-color: $text-color-3;
}

.border-color-origin-red {
  border-color: $text-color-origin-red;
}

.border-color-red {
  border-color: $text-color-red;
}

.border-none {
  border: none;
}

.view-opacity-0 {
  opacity: 0;
}

$space: -20, -10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42,
  44, 46, 48, 50, 58, 60, 70, 75, 80, 90, 98, 100, 110, 120, 125, 130, 140, 147, 150, 155, 157, 160, 165, 170, 175, 180,
  190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 335, 340, 350, 360, 370, 375, 380, 390, 400,
  410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 9999;

@each $sp in $space {
  .m-#{$sp} {
    margin: rpx($sp);
    box-sizing: border-box;
  }

  .ml-#{$sp} {
    margin-left: rpx($sp);
    box-sizing: border-box;
  }

  .mr-#{$sp} {
    margin-right: rpx($sp);
    box-sizing: border-box;
  }

  .mt-#{$sp} {
    margin-top: rpx($sp);
    box-sizing: border-box;
  }

  .mb-#{$sp} {
    margin-bottom: rpx($sp);
    box-sizing: border-box;
  }

  .p-#{$sp} {
    padding: rpx($sp);
    box-sizing: border-box;
  }

  .pl-#{$sp} {
    padding-left: rpx($sp);
    box-sizing: border-box;
  }

  .pr-#{$sp} {
    padding-right: rpx($sp);
    box-sizing: border-box;
  }

  .pt-#{$sp} {
    padding-top: rpx($sp);
    box-sizing: border-box;
  }

  .pb-#{$sp} {
    padding-bottom: rpx($sp);
    box-sizing: border-box;
  }

  .z-#{$sp} {
    z-index: $sp;
  }

  .l-#{$sp} {
    left: rpx($sp);
  }

  .r-#{$sp} {
    right: rpx($sp);
  }

  .t-#{$sp} {
    top: rpx($sp);
  }

  .b-#{$sp} {
    bottom: rpx($sp);
  }

  .w-#{$sp} {
    width: rpx($sp);
  }

  .h-#{$sp} {
    height: rpx($sp);
  }

  .minw-#{$sp} {
    min-width: rpx($sp);
  }

  .minh-#{$sp} {
    min-height: rpx($sp);
  }

  .line-height-#{$sp} {
    line-height: rpx($sp);
  }
.word-spacing-#{$sp} {
  word-spacing: rpx($sp);
}

.letter-spacing-#{$sp} {
  letter-spacing: rpx($sp);
}
  // 超过多少行隐藏变成...
  .omit-text-#{$sp} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $sp * 1;
    -webkit-box-orient: vertical;
  }
}

$brs: 4, 5, 6, 7, 8, 10, 20, 30, 40, 50, 60;

@each $br in $brs {
  .border-radius-#{$br} {
    border-radius: rpx($br);
  }

  .border-radius-tl-#{$br} {
    border-top-left-radius: rpx($br);
  }

  .border-radius-tr-#{$br} {
    border-top-right-radius: rpx($br);
  }

  .border-radius-bl-#{$br} {
    border-bottom-left-radius: rpx($br);
  }

  .border-radius-br-#{$br} {
    border-bottom-right-radius: rpx($br);
  }
}

.border-radius-harf {
  border-radius: 50%;
}

.text-color-p {
  color: $primary-color;
}

.text-color-p-t {
  color: $primary-color-t;
}

.text-color-9 {
  color: $text-color-9;
}

.text-color-6 {
  color: $text-color-6;
}

.text-color-3 {
  color: $text-color-3;
}

.text-color-0 {
  color: $text-color-0;
}

.text-color-white {
  color: white;
}

.text-color-red {
  color: $text-color-red;
}

.text-color-green {
  color: $text-color-green;
}

.text-color-origin {
  color: $text-color-origin;
}

.text-color-origin-red {
  color: $text-color-origin-red;
}

.d-none {
  display: none;
}

.d-shadow {
  box-shadow: rpx(0) rpx(8) rpx(15) rgba(0, 0, 0, 0.1);
}

.d-shadow-p {
  box-shadow: 0 rpx(8) rpx(15) rgba($primary-color, 0.15);
}

.d-flex-r {
  display: flex;
  flex-direction: row;
}

.d-flex-c {
  display: flex;
  flex-direction: column;
}

$flxs: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

@each $fl in $flxs {
  .flex-#{$fl} {
    flex: $fl;
  }
}

$icons: 12, 14, 16, 18, 20, 22, 24, 26, 30, 35, 40, 45, 60, 70, 80, 90, 100;

@each $ic in $icons {
  .icon-#{$ic} {
    width: rpx($ic);
    height: rpx($ic);
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.ai-inherit {
  align-items: inherit;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ai-baseline {
  align-items: baseline;
}

.ac-start {
  align-content: flex-start;
}

.ac-center {
  align-content: center;
}

.ac-end {
  align-content: flex-end;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-evenly {
  justify-content: space-evenly;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}

.text-deleteline {
  text-decoration: line-through;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overflow-x {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-f {
  position: fixed;
}

.omit-text {
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.omit-text-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
    /* 定义文本的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
}

.word-wrap {
  word-wrap: break-word;
}

.word-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.font-widget-600 {
  font-weight: 600;
}

.bottom-box {
  z-index: 350;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mask {
    position: absolute;
    z-index: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .content {
    position: relative;
    z-index: 10;
    border-radius: rpx(10) rpx(10) 0 0;
  }
}

.input-confirm-box {
  border: 1px solid $border-color;
  border-radius: rpx(4);

  .input {
    border: none;
    height: rpx(30);

    &:focus-visible {
      outline: none;
    }
  }
}

.confirm-box {
  z-index: 400;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);

  .content {
    border-radius: rpx(5);
    background-color: white;
    padding-top: 0;
    width: rpx(250);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      text-align: center;
      word-wrap: break-word;
      margin-bottom: rpx(20);
      margin-left: rpx(20);
      margin-right: rpx(20);
      box-sizing: border-box;
      color: $text-color-9;
    }
  }
}

.toast-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;

  .toast {
    position: fixed;
    bottom: 50vh;
    box-sizing: border-box;
    padding: rpx(10);
    margin: rpx(10);
    font-size: rpx(14);
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: rpx(10);
    border: 1px solid #777;
    min-width: rpx(150);
    text-align: center;
    color: white;
    z-index: 9999999999;
  }
}

.error-block {
  margin-top: rpx(50);
  text-align: center;

  .err-img {
    width: 60vw;
  }
}

.inline-block {
  display: inline-block;
}

.loading-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);

  .loading-mask-center {
    width: rpx(100);
    height: rpx(100);
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: rpx(8);

    .loading-pic {
      width: rpx(40);
      height: rpx(40);

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      &.rotating {
        animation: rotate 1s linear infinite;
      }
    }

    .loading-tips {
      margin-top: rpx(14);
      font-size: rpx(12);
      color: white;
      text-align: center;
    }
  }
}

// 复制组件
.copy-to-clipboard {
  .text-area {
    width: 0;
    height: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}