.tab-bar-block-change {
  // height: rpx(48);
  background-color: white;
  display: flex;
    flex-wrap: wrap;
      // overflow-x: auto;

  &.origin-red {
    .tab-bar-item {
      &.active {
        .label {
          color: $text-color-3 !important;
        
        }


      }
    }
  }

  .tab-bar-item {
    flex: 0 0 auto;
    text-align: center;
    display: inline-block;
    // margin-right: 10px;
    margin-left: 10px;
    width: calc(25% - 10px);
      // overflow-y: auto;
      white-space: nowrap;
    &.active {
      .label {
        .rv-notice-bar__content {
            // background-color: rgba(101, 113, 255, 0.12);
            color: #1387ff;
        }
      }

      .active-line {
        background-color: $primary-color;
      }
    }

    &.secondActive {
      .label {
        .title {
          background-color: #1387ff;
          color: #fff;
        }
      }

      .active-line {
        background-color: $primary-color;
      }
    }

    .label {
      height: rpx(35);
        line-height: rpx(33);
      color: $text-color-3;
      font-size: rpx(12) !important;
        padding: 0;
      .title {
        padding: 5px;
        font-weight: 600;
        
        background-color: transparent;
      }

    }


  }
}