.home-page {

  .package-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: rpx(85);
        overflow: hidden;
      .package-info-l {
        width: 38%;
        overflow: hidden;
        // height: 100%;
        border-right: 1px solid #E4EAF2;
        .NoticeBar {
            height: rpx(20);
              font-size: 2.9vh !important;
          }
      }
  
      .word-break {
        word-break: keep-all;
        white-space: nowrap;
      }
  
      .package-info-c {
        width: 30%;
        height: -webkit-fill-available;
        border-right: 1px solid #E4EAF2;
        overflow: hidden;
  
        .text {
          width: 100%;
        }
      }
  
      .package-info-r {
        width: 30%;
        height: 100%;
        // overflow: auto;
  
      }
    }
  
    .img-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      width: 100%;
  
      .img-show {
        width: calc((100% - 10px)/2);
  
        img {
          height: rpx(90);
  
        }
      }
    }
  
    .equipment-info-l {
  
      position: relative;
  
  
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rpx(45);
      }
    }
  
    .equipment-info-c {
      width: 82%;
  
      .equipment-info-item-l {
        width: 45%;
        display: inline-block;
        line-height: rpx(20);
  
        span {
          color: #333333;
        }
      }
  
      .equipment-info-item-r {
        width: 50%;
        display: inline-block;
        line-height: rpx(20);
  
        span {
          color: #333333;
          vertical-align: middle;
        }
  
        img {
          vertical-align: middle;
        }
      }
  
    }
  
    .options-info {
      min-height: rpx(126);
      padding: 10px;
  
      ul {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
  
        li {
          width: calc(100% / 5);
          height: 50%;
          margin-bottom: 1px;
  
          .img-box {
            position: relative;
            width: 100%;
            height: 70%;
  
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: rpx(36);
            }
          }
  
          span {
            text-align: center;
            display: block;
            width: 100%;
            color: #333333;
  
          }
        }
      }
    }
  
    .shoppingCentre-info {
      width: 100%;
  
      .shoppingCentre-data {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 5px;
        background: #f4f3f8;
        .shoppingCentre-data-item {
          width: calc(50% - 5px);
          height: rpx(268);
            // border: 1px solid #E1EAF0;
          overflow: hidden;
          background: #fff;
          .shoppingCentre-data-item-t {
            height: rpx(158);
            width: 100%;
            position: relative;
  
          
  
            img {
              width: 100%;
              height: 100%;
            }
          }
  
          .shoppingCentre-data-item-b {
            height: rpx(80);
            width: 100%;
  
            .shoppingCentre-data-item-title {
              line-height: rpx(25);
              color: rgba(51, 51, 51, 1);
            }
  
  
  
            .shoppingCentre-data-item-people {
              text-align: left;
            }
  
            .tags-box {
              // flex: 1;
              display: flex;
              justify-content: flex-start;
              align-items: start;
              flex-wrap: wrap;
              // overflow: auto;
              padding: 0 5px;
              padding-top: 0px;
              font-size: 12px;
              height: rpx(45);
              .tags-box-l {
                  width: 85%;
                  height: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: start;
                  flex-wrap: wrap;
                }
              
                .shoppingCentre-data-item-share {
                  width: 15%;
                  height: 100%;
                  padding-top: 5px;
              
                  img {
                    width: rpx(16);
                    height: rpx(16);
                  }
                }
              
              .tags {
                padding: 1px;
                  // margin-top: 3px;
                  font-size: 12px;
                  color: #ff5733;
                  border-radius: rpx(5);
                white-space: nowrap;
                background-color: rgba(255, 87, 51, 0.3);
              }
            }
          }
        }
      }
    }
  .btn-shadow {
    box-shadow: 0 rpx(20) rpx(30) rgba($color: $primary-color, $alpha: 0.3);
  }

  .function-btn {
    display: flex;
    flex-direction: column;
    width: rpx(157);
    height: rpx(90);
    padding: rpx(10);
    margin-top: rpx(10);
    position: relative;
    background-color: white;

    border-radius: rpx(10);
    box-sizing: border-box;

    &:nth-child(2n + 1) {
      margin-right: rpx(20);
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  3% {
    transform: translateX(-5px);
  }

  6% {
    transform: translateX(5px);
  }

  9% {
    transform: translateX(-5px);
  }

  12% {
    transform: translateX(5px);
  }

  15% {
    transform: translateX(-5px);
  }

  16% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

.shake-animation {
  animation: shake 4s;
    /* 抖动的持续时间 */
    animation-iteration-count: infinite;
    /* 动画重复次数 */
}

.favorite-dialog {
  height: 100vh;
  width: 100vw;

  .step-img-block {
    width: 80vw;
    height: 70vh;
    margin-left: 10vw;

    .slick-slider {
      height: 56vh;
    }

    .slider-img-block {
      text-align: center;

      .slider-img {
        height: 50vh;
        display: inline-block;
        width: auto;
      }

      .slider-index {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        height: rpx(40);
        line-height: rpx(40);
      }
    }

    .slick-prev {
      left: rpx(10);
      z-index: 999;

      &::before {
        color: $text-color-9;
      }
    }

    .slick-next {
      right: rpx(10);
      z-index: 999;

      &::before {
        color: $text-color-9;
      }
    }
  }
}

.first-active-float {
  position: fixed;
  right: rpx(20);
  bottom: 50vh;
  z-index: 999;
  width: rpx(56);
  height: rpx(65);
}

@keyframes route {
  0% {
    transform: rotate(15deg);
  }

  3% {
    transform: rotate(-15deg);
  }

  6% {
    transform: rotate(15deg);
  }

  9% {
    transform: rotate(-15deg);
  }

  12% {
    transform: rotate(15deg);
  }

  15% {
    transform: rotate(-15deg);
  }

  16% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

.route-animation {
  animation: route 4s;
    /* 抖动的持续时间 */
    animation-iteration-count: infinite;
    /* 动画重复次数 */
}

.adv-dialog {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9999999999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mask {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .adv-img {
    width: rpx(300);
    position: relative;
    z-index: 2;
  }

  .close-btn {
    position: relative;
    z-index: 3;
    width: rpx(30);
    height: rpx(30);
    margin-top: rpx(10);
    border-radius: rpx(30);
    padding: rpx(3);
    background-color: white;
    box-sizing: border-box;

    .icon {
      width: rpx(24);
      height: rpx(24);
    }
  }
}